// src/routes/Insights.js

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 0.5em;
  text-align: center;
`;


const Insights = () => {
  return (
    <Container>
    <Title>Closet insights!</Title>
    
    </Container>
  );
}

export default Insights;