import React, { useState, createContext, useContext } from 'react';
import styled, { css } from 'styled-components';

// Toast Context to be used across the app
const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

// Toast container
const ToastContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: ${(props) =>
    props.type === 'error' ? '#dc3545' : '#28a745'};
  color: white;
  border-radius: 5px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  transition: opacity 0.5s ease;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({ message: '', type: 'success', visible: false });

  const showToast = (message, type = 'success') => {
    setToast({ message, type, visible: true });
    setTimeout(() => {
      setToast((prev) => ({ ...prev, visible: false }));
    }, 3000); // Auto-hide after 3 seconds
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer visible={toast.visible ? "true" : undefined} type={toast.type}>
        {toast.message}
      </ToastContainer>
    </ToastContext.Provider>
  );
};
