import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "../context/AuthProvider";

const Authenticate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fetched, setFetched] = useState(false);
  const { setIsLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (!fetched) {
      const queryParams = new URLSearchParams(location.search);
      const stytchToken = queryParams.get("token");

      if (stytchToken) {
        // Send the token to your backend for verification
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/authenticate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ token: stytchToken }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              console.log("User authenticated and emails accessed:", data);
              // Update login status in local storage or context
              localStorage.setItem("isLoggedIn", "true");
              localStorage.setItem("id_token", data.id_token);
              localStorage.setItem("userkey", data.userkey);

              // will update auth context
              window.dispatchEvent(new Event("storage"));
              navigate("/");
            } else {
              console.error("Authentication failed:", data.message);
            }
            setFetched(true);
          })
          .catch((error) => {
            console.error("Error:", error);
            setFetched(true);
          });
      }
    }
  }, [location, fetched, navigate, setIsLoggedIn]);

  return <div>Authenticating...</div>;
};

export default Authenticate;
