import React, { useState, useEffect, useRef } from 'react'; 
import styled, { keyframes } from 'styled-components';
import mainImage from '../assets/happy_v2.jpg';
import secondImageshaped from '../assets/shaped_digitized.png';
import thirdImageshaped from '../assets/shaped_outfits.png';
import sampleVideo from '../assets/Closetdemo.mp4'; 
import sampleVideo2 from '../assets/Outfitsdemo.mp4'; 
import packingimage from '../assets/packing.png'; 
import { Link } from 'react-router-dom';

const fadeInLeftToRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInRightToLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Container and Section Styles
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FF8650;
  height: 80vh;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
`;

const TextSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: #fff;
  padding-left: 60px;
  animation: ${fadeInLeftToRight} 1s ease-in-out forwards;
`;

const CircularImage = styled.div`
  width: 70%;
  height: 80vh;
  background-image: url(${mainImage});
  background-size: cover;
  background-position: left;
  border-radius: 100% 0 0 100%;
  animation: ${fadeInRightToLeft} 1s ease-in-out forwards;
`;

const scrollAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

const Heading = styled.h1`
  font-size: 3.5em;
  margin: 0 0 20px;
`;

const Button = styled.button`
  padding: 10px 25px;
  font-size: 1em;
  color: #333;
  background-color: #FDE293;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 50px;
  width: 380px;

  &:hover {
    background-color: #fcd54f;
  }
`;

const SecondContainer = styled.div`
  background-color: #FFFFFF;
  padding: 50px 20px;
  text-align: center;
`;

const SecondHeading = styled.h2`
  color: #000000;
  font-size: 2em;
  margin-bottom: 20px;
`;

const SecondSubHeading = styled.p`
  color: #000000;
  font-size: 1.8em;
  margin-bottom: 10px;
`;

const ChangingWordWrapper = styled.div`
  display: inline-block;
  width: 350px;
  height: 1.2em;
  overflow: hidden;
  text-align: left;
  vertical-align: middle;
`;

const ChangingWord = styled.span`
  display: inline-block;
  animation: ${scrollAnimation} 1.5s infinite;
  white-space: nowrap;
  text-decoration: underline;
  color: #fc9139;
`;

const ThirdContainer = styled.div`
  background-color: #FFE66D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  height: 70vh;
  width: 100vw;
  position: relative;
`;

const TextBox = styled.div`
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 40px;
  padding-left: 20px;
  width: 40%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
`;

const TextBoxHeading = styled.h2`
  font-size: 2em;
  color: #000;
  margin-bottom: 10px;
`;

const TextBoxSubHeading = styled.p`
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
`;

const ViewButton = styled.button`
  padding: 12px 20px;
  font-size: 1em;
  background-color: #FDE293;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #fcd54f;
  }
`;

const ImageTwo = styled.div`
  width: 50%;
  background-image: url(${secondImageshaped});
  background-size: cover;
  background-position: center;
  height: 100%;
  padding-right: 20px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-image: none;
  }

  &:hover video {
    opacity: 1;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
`;

const ImageThree = styled.div`
  width: 50%;
  background-image: url(${thirdImageshaped});
  background-size: cover;
  background-position: center;
  height: 100%;
  padding-right: 20px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-image: none;
  }

  &:hover video {
    opacity: 1;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
`;


const ImageFour = styled.div`
  width: 50%;
  background-image: url(${packingimage});
  background-size: cover;
  background-position: center;
  height: 100%;
  padding-right: 20px;
  position: relative;
`;
// Blue Circle styled component
const BlueCircle = styled.div`
  width: 250px; 
  height: 250px; 
  background-color: #ADD8E6; 
  border-radius: 50%;
  position: absolute; 
  top: 40px;  
  center: 0px; 
  z-index: 0; 
`;


const PinkShape = styled.div`
  background-color: #FF6F91; 
  width: 250px; 
  height: 250px; 
  border-radius: 50%;
  position: absolute; 
  top: 40px;  
  center: 0px; 
  z-index: 0; 
`;

const YellowShape = styled.div`
  background-color: #fcd54f; 
  width: 250px; 
  height: 250px; 
  border-radius: 50%;
  position: absolute; 
  top: 40px;  
  center: 0px; 
  z-index: 0; 
`;

const FourthContainer = styled.div`
  background-color: #FFFFFF; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  height: 80vh;  /* Full height */
  position: relative;
  width: 100vw;
`;

const FourthTextBox = styled.div`
  background-color: #BFE3FF; 
  border-radius: 20px;
  padding: 40px;
  width: 45%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FifthContainer = styled.div`
  background-color: #fdcbca;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  height: 80vh;  
  position: relative;
  width: 100vw;
`;


const SplashPage = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = [
    'stylist',
    'wardrobe curator',
    'personal shopper',
    'fashion consultant',
    'trend tracker',
    'outfit optimizer',
    'closet concierge'
  ];

  const videoRef1 = useRef(null); 
  const videoRef2 = useRef(null);

  const handleMouseEnter1 = () => {
    if (videoRef1.current) {
      videoRef1.current.play(); 
    }
  };

  const handleMouseLeave1 = () => {
    if (videoRef1.current) {
      videoRef1.current.pause(); 
      videoRef1.current.currentTime = 0; 
    }
  };


  const handleMouseEnter2 = () => {
    if (videoRef2.current) {
      videoRef2.current.play(); 
    }
  };

  const handleMouseLeave2 = () => {
    if (videoRef2.current) {
      videoRef2.current.pause(); 
      videoRef2.current.currentTime = 0; 
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Container>
        <TextSection>
          <Heading> <i>Cello: </i>mindful fashion, joyful dressing</Heading>
          <Link to="https://n8ikarwap6j.typeform.com/to/X9kn3zqi"> <Button>Join the waitlist</Button></Link>
          <TextBoxSubHeading> Get exclusive first access and a free styling lookbook on us</TextBoxSubHeading>
        </TextSection>
        <CircularImage />
      </Container>

      <SecondContainer>
        <SecondSubHeading>Figuring out what to wear is hard.</SecondSubHeading>
        <SecondHeading>
          Cello is your daily, on-the-go{' '}
          <ChangingWordWrapper>
            <ChangingWord key={currentWordIndex}>
              {words[currentWordIndex]}
            </ChangingWord>
          </ChangingWordWrapper>
        </SecondHeading>
      </SecondContainer>

      <ThirdContainer onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
        <TextBox>
          <TextBoxHeading>Curate your digital closet</TextBoxHeading>
          <TextBoxSubHeading>Get started in 20 minutes. Sync your online purchases and add in your clothes. Create outfits on the go.</TextBoxSubHeading>
          <ViewButton>View your closet</ViewButton>
          <BlueCircle /> 
        </TextBox>
        <ImageTwo>
            <video ref={videoRef1} muted loop>
              <source src={sampleVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ImageTwo>
      </ThirdContainer>

      <FourthContainer>
      <ImageThree onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
            <video ref={videoRef2} muted loop>
              <source src={sampleVideo2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </ImageThree>
          <FourthTextBox>
            <TextBoxHeading>Get <i>hyper</i>-personalized styling</TextBoxHeading>
            <TextBoxSubHeading>Receive outfit recommendations from our professional stylists, tailored to your preferences, based on your closet, and customized to your occasion.</TextBoxSubHeading>
            <ViewButton>View your lookbooks</ViewButton>
          <PinkShape /> 
        </FourthTextBox>
      </FourthContainer>

      <FifthContainer>
          <TextBox>
          <TextBoxHeading>Plan Ahead</TextBoxHeading>
          <TextBoxSubHeading>Going on a trip? Upcoming event? Plan outfits ahead or creating a packing list </TextBoxSubHeading>
          <ViewButton>Start planning</ViewButton>
          <YellowShape /> 
        </TextBox>
        <ImageFour>
          </ImageFour>
      </FifthContainer>
    </div>
  );
};

export default SplashPage;
