import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams, useNavigationType } from "react-router-dom";
import styled from "styled-components";
import { useToast } from "./Toast";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 20px 0;

  @media (min-width: 768px) {
    flex-direction: row-reverse; /* Swaps the order for large screens */
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 600px;
`;

const ItemImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 600px; /* Limit the image size */
  border-radius: 5px;
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
    font-size: 18px;
  }

  p strong {
    font-weight: bold;
  }
`;

const BackButton = styled.button`
  align-self: flex-start; /* Align it to the top left of the content */
  margin: 20px 0; /* Adds space from the top of the container */
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const EditButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #ffc107;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #e0a800;
  }
`;

const DeleteButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #dc3545;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;

const SaveButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const CancelButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #5a6268;
  }
`;

const InputField = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;

const SelectField = styled.select`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;

// Chip (tag) styles
const Chip = styled.div`
  display: inline-block;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  position: relative;
  cursor: default;
`;

const ChipClose = styled.span`
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const VisitStoreButton = styled.a`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ItemDetail = () => {
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  // Get the item from location state (initial state)
  const { item: initialItem } = location.state || {};
  const { item_purchase_id } = useParams();
  const [item, setItem] = useState(initialItem);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { items, setItems } = useContext(ItemsContext);
  const { userkey } = useContext(AuthContext);
  const { showToast } = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [itemName, setItemName] = useState(item ? item.item_name : "");
  const [itemBrand, setItemBrand] = useState(item ? item.item_brand: "");
  const [itemImageUrl, setItemImageUrl] = useState(item ? item.item_image_url: "");
  const [itemStoreUrl, setItemStoreUrl] = useState(item ? item.item_store_url: "");
  const [itemColor, setItemColor] = useState(item ? item.item_color: "");
  const [itemType, setItemType] = useState(item ? item.item_type: "");
  const [itemSubcategories, setItemSubcategories] = useState(
    item && item.item_subcategories ? item.item_subcategories.split(",") : []
  );
  const [newSubcategory, setNewSubcategory] = useState("");

  // Define possible options for the item type
  const itemTypeOptions = ["top", "bottom", "dress", "shoes", "accessories"];

  useEffect(() => {
    // Check if the navigation type is PUSH or POP, which indicates navigation within the app
    if (navigationType === 'PUSH' || navigationType === 'POP') {
      setCanGoBack(true); // Show back button for internal app navigation
    } else {
      setCanGoBack(false); // Hide back button if user directly accessed this page
    }
  }, [navigationType]);

  // Fallback API call to fetch item details if item is not available in location.state
  useEffect(() => {
    if (!initialItem && userkey && item_purchase_id) {
      console.log(item);
      const fetchItem = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/user/${userkey}/item/${item_purchase_id}`
          );
          const data = await response.json();
          if (response.ok) {
            setItem(data);
          } else {
            throw new Error("Failed to fetch item.");
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchItem();
    }
  }, [initialItem, userkey, item_purchase_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!item) {
    // Handle case where item is not available (e.g., redirect or show an error)
    return <div>Item not found</div>;
  }

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer your_token",
          },
          body: JSON.stringify({
            name: itemName,
            brand: itemBrand,
            color: itemColor,
            item_type: itemType,
            image_url: itemImageUrl,
            store_url: itemStoreUrl,
            item_subcategory: itemSubcategories.join(","),
          }),
        }
      );

      if (response.ok) {
        const updatedItem = {
          ...item,
          item_name: itemName,
          item_brand: itemBrand,
          item_color: itemColor,
          item_type: itemType,
          item_image_url: itemImageUrl,
          item_store_url: itemStoreUrl,
          item_subcategory: itemSubcategories.join(","),
        };

        setItem(updatedItem);

        const updatedItems = items.map((i) =>
          i.item_id === item.item_id ? updatedItem : i
        );
        setItems(updatedItems);

        showToast(`Successfully updated ${itemName}.`);
        setIsEditing(false); // Toggle back to view mode
      } else {
        showToast("Failed to update the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while updating the item.", "error");
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  // Handle removing a subcategory
  const handleRemoveSubcategory = (subcategoryToRemove) => {
    setItemSubcategories(
      itemSubcategories.filter((sub) => sub !== subcategoryToRemove)
    );
  };

  // Handle adding a new subcategory
  const handleAddSubcategory = (e) => {
    if (e.key === "Enter" && newSubcategory.trim() !== "") {
      setItemSubcategories([...itemSubcategories, newSubcategory.trim()]);
      setNewSubcategory(""); // Clear the input after adding
    }
  };

  const handleDelete = async () => {
    console.log(JSON.stringify(item, null, 2));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json", // Optional headers
            Authorization: "Bearer your_token", // Optional headers
          },
        }
      );
      if (response.ok) {
        console.log(`Item ${item.item_name} deleted successfully.`);
        const updatedItems = items.filter((i) => i.item_id !== item.item_id);
        setItems(updatedItems);

        showToast(`Successfully deleted ${item.item_name} from your closet.`);
        navigate(-1);
      } else {
        console.error("Failed to delete the item.");
        showToast("Failed to delete the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while deleting the item.", "error");
    }
  };

  return (
    <DetailContainer>
      {canGoBack && (
        <BackButton onClick={() => navigate(-1)}>Back</BackButton>
      )}
      <ContentWrapper>
        <TextContainer>
          {isEditing ? (
            <>
              <InputField
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                placeholder="Item Name"
              />
              <InputField
                value={itemBrand}
                onChange={(e) => setItemBrand(e.target.value)}
                placeholder="Item Brand"
              />
              <InputField
                value={itemColor}
                onChange={(e) => setItemColor(e.target.value)}
                placeholder="Item Color"
              />
              <InputField
                value={itemImageUrl}
                onChange={(e) => setItemImageUrl(e.target.value)}
                placeholder="Item Image URL"
              />
              <InputField
                value={itemStoreUrl}
                onChange={(e) => setItemStoreUrl(e.target.value)}
                placeholder="Store URL"
              />
              <SelectField
                value={itemType}
                onChange={(e) => setItemType(e.target.value)}
              >
                {itemTypeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </SelectField>

              {/* Chips for Subcategories */}
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>
                    {subcategory}
                    <ChipClose
                      onClick={() => handleRemoveSubcategory(subcategory)}
                    >
                      x
                    </ChipClose>
                  </Chip>
                ))}
                <InputField
                  value={newSubcategory}
                  onChange={(e) => setNewSubcategory(e.target.value)}
                  onKeyDown={handleAddSubcategory}
                  placeholder="Add Subcategory and press Enter"
                />
              </ChipContainer>

              <SaveButton onClick={handleSave}>Save Changes</SaveButton>
              <CancelButton onClick={handleEditToggle}>Cancel</CancelButton>
            </>
          ) : (
            <>
              <h1>{item.item_name}</h1>
              <p>
                <strong>Category:</strong> {item.item_type}
              </p>
              <p>
                <strong>Brand:</strong> {item.item_brand}
              </p>
              {item.item_color && (
                <p>
                  <strong>Color:</strong> {item.item_color}
                </p>
              )}
              {item.size && (
                <p>
                  <strong>Size:</strong> {item.size}
                </p>
              )}
              {item.notes && (
                <p>
                  <strong>Notes:</strong> {item.notes}
                </p>
              )}
              {/* View-only subcategories as chips */}
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>{subcategory}</Chip>
                ))}
              </ChipContainer>
              {itemStoreUrl && (
                <VisitStoreButton
                  href={itemStoreUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Visit Store
                </VisitStoreButton>
              )}

              <EditButton onClick={handleEditToggle}>Edit Item</EditButton>
              <DeleteButton onClick={handleDelete}>
                Delete from Closet
              </DeleteButton>
            </>
          )}
        </TextContainer>
        <ImageContainer>
          <ItemImage src={item.item_image_url} alt={item.item_name} />
        </ImageContainer>
      </ContentWrapper>
    </DetailContainer>
  );
};

export default ItemDetail;
