// src/routes/TermsOfService.js

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 0.5em;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 1.5em;
`;

const SubTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 0.5em;
`;

const Text = styled.p`
  line-height: 1.6;
`;

const TermsOfService = () => {
  return (
    <Container>
      <Title>Terms of Service</Title>
      <Section>
        <SubTitle>Introduction</SubTitle>
        <Text>
          These terms of service outline the rules and regulations for the use of our website. By accessing this website we assume you accept these terms of service in full.
        </Text>
      </Section>
      <Section>
        <SubTitle>Intellectual Property Rights</SubTitle>
        <Text>
          Other than the content you own, under these terms, we own all the intellectual property rights and materials contained in this website.
        </Text>
      </Section>
      <Section>
        <SubTitle>Restrictions</SubTitle>
        <Text>
          You are specifically restricted from the following:
          <ul>
            <li>Publishing any website material in any other media without our permission.</li>
            <li>Selling, sublicensing, and/or otherwise commercializing any website material.</li>
            <li>Publicly performing and/or showing any website material.</li>
            <li>Using this website in any way that is or may be damaging to this website.</li>
            <li>Using this website in any way that impacts user access to this website.</li>
            <li>Using this website contrary to applicable laws and regulations.</li>
            <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this website.</li>
          </ul>
        </Text>
      </Section>
      <Section>
        <SubTitle>Your Content</SubTitle>
        <Text>
          In these terms of service, "your content" shall mean any audio, video, text, images or other material you choose to display on this website. By displaying your content, you grant us a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
        </Text>
      </Section>
      <Section>
        <SubTitle>No Warranties</SubTitle>
        <Text>
          This website is provided "as is," with all faults, and we express no representations or warranties, of any kind related to this website or the materials contained on this website.
        </Text>
      </Section>
      <Section>
        <SubTitle>Limitation of Liability</SubTitle>
        <Text>
          In no event shall we, nor any of our officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. We shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this website.
        </Text>
      </Section>
      <Section>
        <SubTitle>Indemnification</SubTitle>
        <Text>
          You hereby indemnify to the fullest extent us from and against any and all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these terms.
        </Text>
      </Section>
      <Section>
        <SubTitle>Severability</SubTitle>
        <Text>
          If any provision of these terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
        </Text>
      </Section>
      <Section>
        <SubTitle>Variation of Terms</SubTitle>
        <Text>
          We are permitted to revise these terms at any time as we see fit, and by using this website you are expected to review these terms on a regular basis.
        </Text>
      </Section>
      <Section>
        <SubTitle>Entire Agreement</SubTitle>
        <Text>
          These terms constitute the entire agreement between us and you in relation to your use of this website and supersede all prior agreements and understandings.
        </Text>
      </Section>
      <Section>
        <SubTitle>Governing Law & Jurisdiction</SubTitle>
        <Text>
          These terms will be governed by and interpreted in accordance with the laws of the State of [Your State], and you submit to the non-exclusive jurisdiction of the state and federal courts located in [Your State] for the resolution of any disputes.
        </Text>
      </Section>
    </Container>
  );
};

export default TermsOfService;
