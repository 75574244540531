import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from '../context/ItemsContext';

function Navbar() {
  const { isLoggedIn, signOut } = useContext(AuthContext);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const { clearItems } = useContext(ItemsContext);
  const fullySignOut = () => {
    signOut();
    clearItems();
  };

  return (
    <div>
      {isDesktopOrLaptop ? (
        <DesktopNavbar isLoggedIn={isLoggedIn} signOut={fullySignOut} />
      ) : (
        <MobileNavbar />
      )}
    </div>
  );
}

export default Navbar;
