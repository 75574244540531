import React from "react";
import styled from "styled-components";
import celloLogo from "../assets/logo.png";
import { Link } from "react-router-dom";

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; /* Decreased padding */
  background-color: white;
  border-bottom: 1px solid #eee;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding-right: 30px; /* Added padding to the right */
  cursor: pointer;

`;

const Logo = styled.img`
  height: 60px; /* Increased height to make the logo bigger */
  width: auto; /* Maintain aspect ratio */
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    color: #ffa500; /* Highlight color */
  }
`;

const LoginLink = styled(NavLink)`
  color: #fc9139; /* Set the color to #e59400 */
`;

const DesktopNavbar = ({ isLoggedIn, signOut }) => {
  return (
    <NavbarContainer>
      <NavLinks>
        <Link to="/">
          <Logo src={celloLogo} alt="Cello Logo" />
        </Link>
      </NavLinks>
      <NavLinks>
        {isLoggedIn && (
          <>
            <NavLink to="/">CLOSET</NavLink>
            <NavLink to="/styleguide">GET DRESSED</NavLink>
            <NavLink to="/insights">INSIGHTS</NavLink>
            <NavLink as="span" onClick={signOut}>
              LOGOUT
            </NavLink>
          </>
        )}
        {!isLoggedIn && <LoginLink to="/login">LOGIN</LoginLink>}
      </NavLinks>
    </NavbarContainer>
  );
};

export default DesktopNavbar;
