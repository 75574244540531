import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd;
`;

const FooterLink = styled(Link)`
  margin: 0 15px;
  text-decoration: none;
  color: #333;
  
  &:hover {
    color: #007bff;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLink to="/privacy">Privacy Policy</FooterLink>
      <FooterLink to="/terms">Terms of Service</FooterLink>
    </FooterContainer>
  );
};

export default Footer;
