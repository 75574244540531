import React, { useState } from "react";
import styled from "styled-components";
import ItemCard from "../components/ItemCard";
import { useNavigate } from "react-router-dom";

const HomePageContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px; // top and bottom padding, left and right padding
  width: 100%;
`;

const AddItemButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #218838;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ItemGrid = styled.div`
  flex-grow: 1; // Allows the grid to take the remaining height
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 items per row for medium screens */
  }

  @media (min-width: 600px) and (max-width: 899px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 items per row for smaller screens */
  }

  @media (max-width: 599px) {
    grid-template-columns: 1fr; /* 1 item per row for very small screens */
  }
`;

const CategoryButton = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: ${({ selected }) => (selected ? "black" : "white")};
  color: ${({ selected }) => (selected ? "white" : "black")};
  cursor: pointer;
  flex: 1 0 20%; // Flex properties for responsive layout
  max-width: 150px; // Adjust max-width as needed

  &:hover {
    background-color: grey;
    color: white;
  }

  @media (max-width: 768px) {
    display: ${({ isDropdown }) => (isDropdown ? "block" : "none")};
    width: 100%;
    max-width: 100%;
    margin: 5px 0;
  }
`;

const DropdownButton = styled.button`
  display: none;
  width: 100%;
  padding: 10px;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 16px;

  &:hover {
    background-color: grey;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const DropdownContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  width: 100%;
  text-align: center;
  border-top: 1px solid #eee;
  padding: 10px 0;
`;

const categories = ["all", "top", "bottom", "dress", "shoes", "accessories"];

const HomePage = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setIsOpen(false); // Close the dropdown when a category is selected
  };

  const handleAddItemClick = () => {
    navigate("/item/new"); // Navigate to the new item creation page
  };

  const filteredItems = items.filter((item) => {
    if (selectedCategory === "all") return true;
    return item.item_type.toLowerCase() === selectedCategory;
  });

  return (
    <HomePageContainer>
      <ControlsContainer>
        <AddItemButton onClick={handleAddItemClick}>Add Item</AddItemButton>
        <FilterContainer>
          <DropdownButton onClick={() => setIsOpen(!isOpen)}>
            {selectedCategory}
          </DropdownButton>
          <DropdownContent $isOpen={isOpen}>
            {categories.map((category) => (
              <CategoryButton
                key={category}
                onClick={() => handleCategoryClick(category)}
                selected={selectedCategory === category}
                $isDropdown
              >
                {category}
              </CategoryButton>
            ))}
          </DropdownContent>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              onClick={() => handleCategoryClick(category)}
              selected={selectedCategory === category}
            >
              {category}
            </CategoryButton>
          ))}
        </FilterContainer>
      </ControlsContainer>
      <ItemGrid>
        {filteredItems.map((item) => (
          <ItemCard key={item.item_id} item={item} />
        ))}
      </ItemGrid>
    </HomePageContainer>
  );
};

export default HomePage;
