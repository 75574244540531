import React, { createContext, useState } from 'react';

export const ItemsContext = createContext();

export const ItemsProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const clearItems = () => {
    setItems([]);
  };

  return (
    <ItemsContext.Provider value={{ items, setItems, loading, setLoading, error, setError, clearItems }}>
      {children}
    </ItemsContext.Provider>
  );
};
