// src/routes/PrivacyPolicy.js

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 0.5em;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 1.5em;
`;

const SubTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 0.5em;
`;

const Text = styled.p`
  line-height: 1.6;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
    <Title>Privacy Policy</Title>
    <Section>
      <SubTitle>Introduction</SubTitle>
      <Text>
        Your privacy is important to us. This privacy policy document outlines the types of personal information that is received and collected by our website and how it is used.
      </Text>
    </Section>
    <Section>
      <SubTitle>Information We Collect</SubTitle>
      <Text>
        We collect information in various ways, including:
        <ul>
          <li>Information you provide directly to us.</li>
          <li>Information collected automatically when you use our services.</li>
          <li>Information from third-party sources.</li>
        </ul>
      </Text>
    </Section>
    <Section>
      <SubTitle>Google User Data</SubTitle>
      <Text>
        Our application accesses Google user data specifically your email data, which is used to get clothing purchases. We do not store, share, transfer, or disclose your Google user data to any third-parties. We only use this data as described in this policy.
      </Text>
    </Section>
    <Section>
      <SubTitle>Data Protection Mechanisms</SubTitle>
      <Text>
        We take the protection of your data seriously and implement appropriate technical and organizational measures to protect your information from unauthorized access, use, or disclosure.
      </Text>
    </Section>
    <Section>
      <SubTitle>Retention and Deletion of Google User Data</SubTitle>
      <Text>
        We do not store any Google user data. The data accessed is used temporarily during the session and is not retained or stored on our servers after the session ends.
      </Text>
    </Section>
    <Section>
      <SubTitle>How We Use Your Information</SubTitle>
      <Text>
        The information we collect is used in the following ways:
        <ul>
          <li>To provide, operate, and maintain our services.</li>
          <li>To improve, personalize, and expand our services.</li>
          <li>To understand and analyze how you use our services.</li>
          <li>To develop new products, services, features, and functionality.</li>
        </ul>
      </Text>
    </Section>
  </Container>
  );
}

export default PrivacyPolicy;