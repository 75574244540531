import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Card = styled.div`
  position: relative;
  // margin: 10px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden; /* Ensure the image fits within the container */
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    height: 480px; /* Set a fixed height for the images */
    object-fit: cover; /* Cover the entire container */
  }

  .content {
    padding: 10px;
    text-align: center;
  }

  .tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    color: black;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
  }
`;

const ItemCard = ({ item }) => {
  return (
    <Card>
      <Link to={`/item/${item.purchase_id}`} state={{ item }}>
        {item.source == 'EMAIL' && <div className="tag">imported from email</div>}
        <img src={item.item_image_url} alt={item.item_name} />
        <h3>{item.item_name}</h3>
        <p>{item.item_brand}</p>
      </Link>
    </Card>
  );
};

export default ItemCard;
