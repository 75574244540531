import React from 'react';
import styled from 'styled-components';
import celloLogo from '../assets/logo.png';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  border-bottom: 1px solid #eee;
`;

const MenuIcon = styled.div`
  cursor: pointer;
`;

const Logo = styled.img`
  height: 38px; // Adjust the size as needed
`;


const MobileNavbar = () => {
  return (
    <NavbarContainer>
      <MenuIcon>☰</MenuIcon>
      <Logo src={celloLogo} alt="Cello Logo" />
      <MenuIcon>🔍</MenuIcon>
    </NavbarContainer>
  );
};

export default MobileNavbar;
